.layout-container{
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
}

.layout-container .layout-outlet{
    /* padding: 1rem; */
    height: 100%;
    align-self: flex-start;
    width: 100%;
    background-color: white;
}