.login-main-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 90vh;
}

.floating-login-container {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
}

.sign-in-page-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-page-input-text {
  width: 100%;
  height: 50px;
  border: 1px solid #004225;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 16px;
}

.signIn-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.signIn-login-btn,
.signIn-sso-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.signIn-login-btn {
  background-color: #004225;
  color: white;
}

.signIn-login-btn:hover {
  background-color: #0f6d44;
}

.signIn-sso-btn {
  background-color: #2f2f2f;
  color: white;
}

.signIn-sso-btn:hover {
  background-color: #5c5252;
}


.signedpages {
  background-color: white;
  display: flex;
  justify-content: space-between;
  
}

.sign-in-page {
  margin-top: 70px;
  margin-left: 140px;
  min-width: 572px;
  max-width: 600px;
}

.ssoLoading {
  backdrop-filter: blur(4px);
  position: absolute;
  width: 50%;
  height: 100%;
  margin-left: -10px;
  margin-top: -10px;
}

.ssoLoading div {
  position: absolute;
  left: 20%;
  top: 50%;
}

.sign-up-page {
  margin-top: 70px;
  margin-left: 140px;
  min-width: 575px;
  max-width: 1000px;
  

}

.signedpages > img {
  height: 100vh;
}

.signed-header {
  font-size: 26px;
  font-weight: 700;
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.signed-sub-header {
  font-size: 25px;
  margin-top: 25px;
}


.sign-up-page-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.sign-up-page-section input {
  margin-right: 15px;
}

.first-time-here-tip{
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type="button"] {
  background-color: #004225;
  color: white;
  max-width: 300px;
  padding: 0;
  text-transform: uppercase;
  font-family: Roboto, Rubik, sans-serif;
}

.sso {
  background-color: #2f2f2f !important;
  color: white !important;
  width: 400px;
}

.sso img {
  width: 200px;
  float: left;
}

::placeholder {
  color: #b0bec5;
}

button.resetBtn {
  background-color: #004225;
  color: white;
  padding: 0;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  height: 80px;
  max-width: 522px;
  min-width: 400px;
  /* UI Properties */
  border: 1px solid #004225;
  border-radius: 23px;
  margin-bottom: 30px;
  font-size: 25px;
 
  
}

.wisdom-logo-img{
  width: 310px;
  height: 130px;
}


.signIn-btns button{
  border-radius: 2px;
  font-weight: bolder;
}

.signIn-btns button:not(:disabled){
  transition: all .2s ease;
}

.signIn-btns .signIn-login-btn:disabled{
  background-color: #80919a;
}

.signIn-btns .signIn-login-btn:not(:disabled):hover{
  background-color: #0f6d44;
}

.signIn-btns .signIn-sso-btn:not(:disabled):hover{
  background-color: #5c5252;
}


.footer-login-page{
  height: 10vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.divider {
  border: none;
  height: 1px;
  width: 100%;
  background-color: #000000;
  margin: 10px 0;
}

.top-divider{
  border: none;
  height: 1px;
  width: 100%;
  background-color: #000000;
  margin: 50px 0 10px;
}

.login-page-footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  flex-direction: column;

}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}
