
.employee-summary-main{
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.my-dashboard-header{
  width: calc(100vw - 5vw);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  background-color: #02fa8e0c;
}


.employee-summary-info{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0 0 4rem;
}

.summary-charts-values {
  display: flex;
  gap: 1rem; 
  width: 100%;
}

.employee-summary-cards, 
.employee-summary-chard-card {
  width: 50%; 
}

.employee-summary-chard-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-summary-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 1rem;
  width: 100%;
}

.employee-summary-cards > *:nth-child(n+5) {
  grid-column: span 1; 
}

@media only screen and (max-width: 1024px) {
  .employee-summary-cards {
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media only screen and (max-width: 768px) {
  .employee-summary-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

